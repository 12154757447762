export const projectsData = [
  {
    id: '1',
    title: 'ambientprofesional',
    description:
      'complex e-commerce solution with accounting, CRM and  CMS solution.',
    text:
      "When talking on the macroscopic level, ecommerce solutions can be considered as components that streamline the business process and ease business conduction. Ecommerce solutions are the subset of ecommerce, as they also deploy ecommerce to successfully conduct their business. This is how ecommerce and ecommerce solutions are closely benefiting each other and helping other businesses grow.",
    image: {
      src: 'https://starsoftdesign.ro//assets/images/c.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.ambientprofesional.ro',
      repo: 'https://github.com/vsnaichuk/apiko-marketplace-app-snaichuk',
    },
    technologies: [
      '#react',
      '#Php',
      '#socket.io',
      '#scss',
      '#woocommerce',
      '#MySQL',
      '#restapi',
    ],
  },
  {
    id: '2',
    title: 'Places App',
    description:
      'A MERN app to let users share their experience of places along with Details. It is user friendly and can be useful to many others.',
	text:
      ".",	

    image: {
      src: 'https://res.cloudinary.com/dx6tl6aa2/image/upload/v1628348070/portfolio/projects/mern-places/mern-places-lg_xf6zom.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: 'https://github.com/vsnaichuk/MERN-places',
    },
    technologies: [
      '#react',
      '#nodejs',
      '#express',
      '#mongodb',
      '#react-query',
      '#react-transition-group',
      '#mongoose',
      '#jwt',
      '#aws',
    ],
  },
  {
    id: '3',
    title: 'eCommerce Mobile App',
    description:
      'A Mobile eCommerce application built with React  that includes cart functionality, push notifications, user authentication and user orders',
    text:
      "Story, articles, potcasts, courses and much more",
    image: {
      src: 'https://starsoftdesign.ro//assets/images/f.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://antoanetabanu.ro',
      repo: 'https://github.com/vsnaichuk/RN-shop',
    },
    technologies: [
      '#react-native',
      'expo',
      '#redux',
      '#redux-toolkit',
      '#react-navigation',
      '#nodejs',
      '#express',
      '#mongodb',
    ],
  },
  {
    id: '4',
    title: 'Food Ordering App',
    description:
      'An online food delivery application',
	text:
      "This is a  project created with React.js, Redux,Html and CSS for ordering food online. The website includes features such as user authentication, a home page with a menu, category pages, detailed food pages, a user profile, and a shopping cart.",
    image: {
      src: 'https://res.cloudinary.com/dx6tl6aa2/image/upload/v1628348073/portfolio/projects/rn-places/rn-places-lg_ljyikm.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://pizzeriaclinceni.ro',
      repo: 'https://pizzeriaclinceni.ro',
    },
    technologies: [
      '#react',
      '#typescript',
      '#expo',
      '#mobx',
      '#sql',
      '#react-native-maps',
      '#nodejs',
    ],
  },
  {
    id: '5',
    title: 'Company website',
    description:
      'Responsive Modern UI/UX Website | React.js, Next.js 13, Tailwind CSS. Uses SWR hook to fetch the data from the API and Styled Components',
	    text:
      "The application is responsive and works great on mobile devices and tablets.",
    image: {
      src: 'https://res.cloudinary.com/dx6tl6aa2/image/upload/v1628348073/portfolio/projects/react-shop-cart/rn-shop-cart-lg_w3qxn6.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://aeconengineering.com/',
      repo: 'https://github.com/vsnaichuk/React-ShoppingCart',
    },
    technologies: [
      '#react',
      '#typescript',
      '#material-ui',
      '#styled-components',
      '#swr',
      '#nextjs',
    ],
  },
];
