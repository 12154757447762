import { Link } from "react-router-dom";
//import Button from "../components/Button";
//import ContentBox from "../components/contents/ContentBox";
//import PageWrapper from "../components/PageWrapper";

// 404 page not found error page
const NotFoundPage = () => {
  return (

     <section className="relative z-10 bg-blue-600 py-[120px]">
      <div className="container">
        <div className="-mx-4 flex">
          <div className="w-full px-4">
            <div className="mx-auto max-w-[400px] text-center">
              <h2
                className="mb-2 text-[50px] font-bold leading-none text-red-600 sm:text-[80px] md:text-[100px]"
              >
                404
              </h2>
	              <img
          src="../assets/404.svg"
          alt="404 page"
        />
              <h4
                className="mb-3 text-[22px] font-semibold leading-tight text-blue-500"
              >
                Oops! That page can't be found
              </h4>
              <p className="mb-8 text-lg text-white">
                The page you are looking for it maybe deleted
              </p>
	                <Link to={"/"}>
            <button className="inline-block rounded-lg border border-black px-8 py-3 text-center text-base font-semibold bg-red-600 text-white transition  hover:bg-green-500 hover:text-primary">
              <span>&larr; Back to home &rarr;</span>
            </button>
          </Link>

            </div>
          </div>
        </div>
      </div>

      <div
        className="absolute top-0 left-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14"
      >
        <div
          className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"
        ></div>
        <div className="flex h-full w-1/3">
          <div
            className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"
          ></div>
          <div
            className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"
          ></div>
        </div>
        <div
          className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"
        ></div>
      </div>
    </section>

  );
};

export default NotFoundPage;
