import laptop from "../../assets/robotstarm1.png";
import Modal from "../form/Modal";

const Analytics = () => {
  return (
    <section className="relative py-20" id="services">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div
        className="w-full bg-white py-16 px-4"
        data-aos="zoom-in"
        data-aos-duration="800"
        data-aos-delay="400"
      >
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <img className="w-[500px] mx-auto" src={laptop} alt="" />
          <div className="flex flex-col justify-center">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
              <i className="fas fa-rocket text-xl"></i>
            </div>
            <p className="uppercase text-[#00df9a] font-bold">
              A growing company
            </p>
            <p className="mt-4 text-lg leading-relaxed text-gray-600">
              STAR SOFT Design integrates custom software development and
              professional design services.
            </p>
            <p>
              STAR SOFT Design offers the complete range of services for custom
              Web design and development. We are a team with a wide variety of
              skills, who can take your Web application or Web site from design
              concept to realization, and through maintenance -- on both the
              visual and software development fronts.
            </p>
            <p>
              Different projects require a different mix of the services that we
              provide, but, in general, all of them require some degree of
              consulting, design, development, and support.
            </p>
            <ul className="list-none mt-6">
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                      <i className="fas fa-fingerprint"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600 font-bold">Consulting</h4>
                    <p>
                      Our engagements begin with understanding your needs. We
                      take a pragmatic approach to Web design and development.
                      While we're committed to staying abreast of the latest
                      technologies, we don't choose them for their own sake. And
                      while years and breadth of experience make it a lot easier
                      to assess what the issues are, we don't believe we know
                      the best solution before talking with you. Our consultant
                      team will work with you to identify the areas to be
                      addressed and propose a solution that takes your unique
                      circumstances into account.
                    </p>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3">
                      <i className="fab fa-html5"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600 font-bold">
                      Software and Graphic Design{" "}
                    </h4>
                    <p>
                      During a project's design phase, our software development
                      and graphic design groups work in parallel, maintaining
                      constant communication to ensure a successful project. On
                      the software design side, we draw up a detailed roadmap,
                      covering everything from the navigation and site structure
                      to the database design to any interfaces with existing
                      systems. On the graphic design side, we work to develop
                      visual solutions that communicate and make applications
                      easier to use.
                    </p>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                      <i className="far fa-paper-plane"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600 font-bold">Development</h4>
                    <p>
                      After a design is approved, our team of software
                      developers and Web production staff take the design and
                      make it a reality. Successful Web development requires a
                      range of skills that are not learned overnight. We have
                      extensive experience in React, React Native, HTML, CSS3,
                      Java, ASP, .NET, JSP, PHP, DHTML, Oracle, SQL Server,
                      MySql and much more.
                    </p>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200 mr-3">
                      <i className="far fa-paper-plane"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600 font-bold">
                      Support and Maintenance
                    </h4>
                    <p>
                      Web applications and Web sites, particularly complex ones,
                      will require maintenance as your needs change and grow.
                      With many Web development companies, the team that
                      develops the site is no longer available to provide
                      ongoing support and enhancements. At Star Soft Group, we
                      are committed to continuing to partner with you to support
                      your organization's needs beyond Web site launch or
                      application rollout.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Manage well your projects
            </h1>
            <p>
              Considering your time and satisfaction as very important, we are
              at your disposal with complete services in the IT field: from web
              development, web design, web hosting, ISP to consultancy and
              specialized technical solutions
            </p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Modal tip={"all"} />
        {'   '}
            <a href="/page/services">
              <button
                className="text-center bg-blue-600 w-[150px] uppercase font-bold rounded-md my-6 mx-auto py-3 
                    text-white shadow-lg shadow-gray-800 hover:scale-110 hover:shadow-2xl 
                    hover:shadow-black duration-300 active:scale-100"
                type="button"
              >
                View More &rarr;
              </button>
            </a>
      </div>
    </section>
  );
};

export default Analytics;
