import React, { useState } from "react";
//import Loader from "../components/Loader";
import http from "../../http-common";
const FORM_ENDPOINT = "http://localhost:8080/SendMail/sendMail";
const BASE_URL = process.env.REACT_APP_CONTACT_URL; // TODO - fill on the later step http://localhost:8080/SendMail/sendMail

const ContactForm = () => {
const Loader = () => {
    let circleCommonClasses = 'h-2.5 w-2.5 bg-current   rounded-full';

    return (
        <div className='flex'>
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div
                className={`${circleCommonClasses} mr-1 animate-bounce200`}
            ></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
        </div>
    );
};

		console.log(BASE_URL);
  const [status, setStatus] = useState();
    const [bdisiable, setBdisiable] = useState(false);
    const [loading, setLoading] = useState();

  const handleSubmit = (e) => {

    e.preventDefault();
setLoading(true);
    // Anything you need to inject dynamically

    const injectedData = {

      DYNAMIC_DATA_EXAMPLE: 123,

    };
	setBdisiable(true);

    const inputs = e.target.elements;

    const data = {};

    for (let i = 0; i < inputs.length; i++) {

      if (inputs[i].name) {

        data[inputs[i].name] = inputs[i].value;

      }

    }
	console.log(data);
	console.log(process.env.REACT_APP_CONTACT_URL);
		console.log(FORM_ENDPOINT);
 let urlWithString =
	    BASE_URL+`?mailTo=` +
        data['email'] +
        `&name=` +
        data['name'] +
	         `&mobile=` +
        data['mobile'] +
        `&message=` +
        data['msg'];// + '&lang=' + localStorage.getItem('i18nextLng');
    Object.assign(data, injectedData);
  /*  
    fetch(urlWithString, {

      method: "get",

  headers: {

        Accept: "application/json",

        "Content-Type": "application/json",

      },

      body: JSON.stringify(data),

    })*/
	setStatus("We'll be in touch soon!!!");
http.post("/contact/", {
					name: data['name'],
					mobile: data['mobile'],
					email: data['email'],
					msg: data['message'],
                    subject:'Contact from stratsoftdesign.ro',
				  })
	 .then((response) => {

        // It's likely a spam/bot request, so bypass it to validate via captcha

        if (response.status === 422) {

          Object.keys(injectedData).forEach((key) => {

            const el = document.createElement("input");

            el.type = "hidden";

            el.name = key;

            el.value = injectedData[key];

            e.target.appendChild(el);

          });

          e.target.submit();

          throw new Error("Please finish the captcha challenge");

        }

        if (response.status !== 200) {

          throw new Error(response.statusText);

        }

        return response.json();

      })					
      .then(() => {setStatus("We'll be in touch soon.")})
		//  setLoading(False);

      .catch((err) => setStatus(err.toString()));
setLoading(false);
//setStatus("We'll be in touch soon!!!");
  };
  console.log("status:",status);
  if (status) {

    return (

      <>

        <div className="text-2xl">Thank you!</div>

        <div className="text-md">{status}</div>

      </>

    );

  }
    if (loading) {

    return (

      <>

					  <Loader />

      </>

    );

  }

  return (

    <form

      action={FORM_ENDPOINT}

      onSubmit={handleSubmit}

      method="POST"

      target="_blank"

    >
 <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Your name
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Full Name" name="name" required
                      />
                    </div>


                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Email" name="email" required
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="Phone"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Your phone number" name="mobile" 
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Type a message..." name="message" required
                      />
                    </div>

                     <div className="text-center mt-6">
                      <button
	
                        className="bg-blue-500 text-white active:bg-blue-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-green-600" disabled={bdisiable}
                        type="submit"
                      >
                        Send Message
                      </button>


						 
                    </div>



   
    </form>

  );

};

export default ContactForm;