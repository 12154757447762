import Modal from "./form/Modal";
//import styles from "./style";

const Newsletter = () => {
  return (
	  <>
    <div className='w-full py-16 text-white bg-[#000300] px-4'>

      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>


		            <h1 className='md:text-5xl sm:text-4xl text-xl font-bold py-2'>
            Let's try our service now!
          </h1>
          <p>  Everything you need to grow your business
        anywhere on the planet.</p>

        </div>
        <div className='my-4'>
          <div className='flex flex-col sm:flex-row items-center justify-between w-full'>

			<Modal tip={"all"}/>
          </div>
          <p className='text-white'>
            We care about the protection of your data. Read our
            <span className='text-[#00df9a] cursor-pointer ml-1'>
              Privacy Policy.
            </span>
          </p>
        </div>
      </div>

    </div>

				  </>
  );
};

export default Newsletter;
