import React, { useState, useEffect } from "react";
//import { BiLinkExternal } from 'react-icons/all';
import Modal from '../../components/Modal/Modal';
import CustomModal from '../../components/Modal/CustomModal';
import Button from '../../components/UIElements/Button/Button';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useModal } from '../../hooks/modalHook';
import { projectsData } from '../../data/projectsData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import "./styles.css";
import Meta from '../Meta'; 
import { Navbar } from "../";

const ModalProjectCard = () => {
  const { id } = useParams();
  console.log(id);
  const { image, title, description, text, links, technologies } = projectsData.find(
    (p) => id === p.id,
  );
  console.log(title);
    console.log(image.src);
  const { isVisible, toggleModal } = useModal();
 const navigate = useNavigate();
  useEffect(() => {
    toggleModal();
  }, []);


  const [showModal, setShowModal] = useState(true);
  //console.log({tip});
  return (

  <>
 <Meta title={title} />

	        <div className='w-full h-screen relative overflow-hidden'>
        {/* Background image */}
        <div className="h-full bg-[url('assets/background.jpg')] bg-cover"></div>
        <div className='absolute top-0 w-full'>
          <Navbar transparent />

       <div className='text-white'>
      <div className='max-w-[800px] mt-[-46px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center">
              <span className="mb-2 block text-lg font-semibold text-primary">
                HERE ARE SOME OF OUR CUSTOM PROJECTS
              </span>
              <h2
                className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]"
              >
                Our Recent Projects
              </h2>
              <p className="text-base text-body-color">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
</div>
				  </div>

        </div>
      </div>


               <CustomModal visible={showModal} onClose={() => {setShowModal(false); navigate('/projects');}}>
<div className=" pt-5 p-5 overflow-y-auto fixed inset-0 z-50" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
            <div className="relative w-auto p-2 pt-10 my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
  

 <div className="relative bg-transparent">
                  <LazyLoadImage
            alt="card-img"
            src={image.src}
            width="100%"
            style={{ minHeight: '10rem' }}
            placeholderSrc={image.placeholderSrc}
          />

	                   <button
                    className="absolute text-2xl text-red-600 top-5 right-5"
                    type="button"
                    onClick={() =>  {setShowModal(false); navigate('/projects');}}
                  >
                    X
                  </button>
        <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-gray-800 opacity-70">
            <h3 className="text-xl text-white font-bold">
                {title}</h3>
            <p className="mt-2 text-sm text-gray-300"> {technologies.map((tech) => (
              <span className="bg-green-500 rounded-3xl shadow-md" key={tech}> {tech}&nbsp; </span>
            ))}</p>
	   
        </div>
    </div>


                <div className="relative p-6 flex-auto">
		
		 <h1 className="heading">{title}</h1>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                   {description}
                    </p>
		    <div dangerouslySetInnerHTML={{ __html: text}} />
  
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b bg-blue-500">
					         <button
            style={{ width: '10rem' }}
            className="text-white bg-blue-600 hover:bg-green-600 hover:shadow-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
            
          >
            <a href={links.site}
            target="_blank">&nbsp; View project</a>
          </button>
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() =>  {setShowModal(false); navigate('/projects');}}
                  >
                    Close
                  </button>

                </div>
              </div>
            </div>
          </div>


      </CustomModal>
</>
  
    
  );
};

export default ModalProjectCard;