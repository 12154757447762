import React from "react";
import { Link } from 'react-router-dom';
import Modal from "./form/Modal";

const Start = () => {
  return (
           <section className="pb-20 bg-gray-300 -mt-24" id="aboutus">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center" data-aos="slide-left" data-aos-duration="800" data-aos-delay="400">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Satisfaction</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Considering your time and satisfaction as very important, we are at your disposal with complete services in the IT field
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Solutions
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      The solutions that STAR SOFT DESIGN makes available to you will adapt perfectly to the needs of your company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center" data-aos="slide-right" data-aos-duration="800" data-aos-delay="400">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Quality
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Specialization in a field of activity guarantees a quality service. STAR SOFT DESIGN have specialists to be able to offer you professional solutions
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto" data-aos="fade-right" data-aos-duration="800" data-aos-delay="400">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Working with us is a pleasure
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
STAR SOFT DESIGN is a global information technology company. Our team has a proven reputation for delivering high quality software solutions across a broad spectrum of technologies also focusing at professional shareware development
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
We provide software development, system integration and consulting services, where the user requirements are analyzed and complete hardware, networking and software solutions are designed and implemented.  Whether you're looking for help with
                cloud computing, data management, cybersecurity, or any other
                area of IT, we have the knowledge and resources to help you
                succeed. We pride ourselves in providing the highest quality of
                customer service and support, and we are committed to delivering
                solutions that are reliable, scalable, and cost-effective.
                Contact us today to learn more about how we can help your
                business grow in the digital age.
                </p>
 			
                <Link to="/" className="font-bold text-gray-800 mt-8">
                  Check Star Soft Design!
                </Link>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="400">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-blue-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-gray">
                      Top  Services
                    </h4>
                    <p className="text-md font-light mt-2 text-gray">
STAR SOFT DESIGN primary areas of expertise are in providing quality, cost effective software development and outsourcing services to our clients. 
                    </p>
                  </blockquote>
						 
                </div>
					
              </div>

            </div>
          </div>
				 <div className='text-center flex flex-col justify-center'>

				 <Modal />
</div>
				 </section>
  );
};

export default Start;
