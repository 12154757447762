import React, { useState } from "react";
import CerereForm from "./CerereForm";

const Modal = ({ tip }) => {
  const [showModal, setShowModal] = useState(false);
  console.log({tip});
  return (
    <>
      <button
        className="bg-[#00df9a] w-[150px] uppercase font-bold rounded-md my-6 mx-auto py-3 
                    text-blue-900 shadow-lg shadow-gray-800 hover:scale-110 hover:shadow-2xl 
                    hover:shadow-black duration-300 active:scale-100"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Request offer &rarr;
      </button>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t bg-blue-500">
                  <h3 className="text-3xl  font=semibold">Request </h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-red-500 text-center opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full">X
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                                     <h4 className="text-2xl font-semibold">
                      Want to work with us?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                      Complete this form and we will get back to you in 24
                      hours.
                    </p>
                    <CerereForm tip={{tip}}/>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b bg-blue-500">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>

                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;