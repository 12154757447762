
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProjectCard = ({ id, image, title, description }) => {
  const location = useLocation();

  return (
  
    <div className="w-full px-4 md:w-1/2 xl:w-1/3" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="400" >
    <div className="relative mb-12">
             <div className="overflow-hidden rounded-lg w-full">
             <Link
          to={{
            pathname: `/project/${id}`,
            state: {
              background: location,
            },
          }}
        >
          <LazyLoadImage
            alt="card-img"
            src={image.src}
            width="100%"
            style={{ minHeight: '10rem' }}
            placeholderSrc={image.placeholderSrc}
          />
        </Link>
             </div>
             <div
               className="relative z-10 mx-7 -mt-20 rounded-lg bg-white py-9 px-3 text-center shadow-lg"
             >
               <span className="mb-2 block text-xl font-bold  text-primary">
               {title}
               </span>
               <h3 className="mb-4 text-sm font-semibold text-dark">
               {description}
               </h3>
               <Link
          to={{
            pathname: `/project/${id}`,
            state: {
              background: location,
            },
          }}
          className="inline-block rounded-md border py-3 px-7 text-sm font-semibold text-body-color transition hover:border-primary hover:bg-blue-600 hover:text-white">
View Details
        </Link>
 
             </div>
           </div>
</div>
  );
};

export default ProjectCard;
