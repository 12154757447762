import React from "react";
import { Navbar, Footer, Skills, Analytics } from "../components";

import Hero from "../components/Hero";
import Start from "../components/Start";
import ContactForm from "../components/form/ContactForm";

import Newsletter from "../components/Newsletter";
import PaymentPlanCards from "../components/PaymentPlanCards";
import Meta from "../components/Meta"; // <Meta title="Star soft design - home" />

const Home = () => {
  return (
    <>
      <Meta title="home" />
      <div className="w-full h-screen relative overflow-hidden">
        {/* Background image */}
        <div className="h-full bg-[url('assets/tech-bg.webp')] bg-cover"></div>
        <div className="absolute top-0 w-full">
          <Navbar transparent />
          <Hero />
        </div>
      </div>
      {/* First section */}

      <div
        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
        style={{ height: "70px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <Start />
      {/* Second section */}
      <Analytics />
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">
                Here are cuple of technology we used
              </h2>
              <p className="text-lg leading-relaxed m-4 text-gray-600">
                For building an applcation, we have many available technologies,
                and based on your requirement we should choose one
              </p>
            </div>
          </div>
          <Skills />
        </div>
      </section>

      <Newsletter />

      {/* Third section section */}
      <PaymentPlanCards />

      <section
        className="relative block py-20 lg:pt-20 bg-gray-900"
        id="contact"
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center lg:-mt-24 -mt-24">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl font-semibold">
                    Want to work with us?
                  </h4>
                  <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style={{ height: "70px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-black fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      {/* Final section */}
    </>
  );
};

export default Home;
