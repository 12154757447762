import React from 'react';
//import Marquee from "react-fast-marquee"; ,{ useContext }

import './Skills.css'

//import { ThemeContext } from '../../contexts/ThemeContext';
import { skillsData } from '../../data/skillsData'
import { skillsImage } from '../../utils/skillsImage'

function Skills() {

	/*const { theme } = useContext(ThemeContext);

    const skillBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 30px ${theme.primary30}`
    } */

    return (

		 <div id ="skills" className="flex flex-wrap">
 {skillsData.map((skill, id) => (
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4" key={id} data-aos="zoom-in" data-aos-duration="800" data-aos-delay="400+2*{id}">
                <div className="px-6">
                  <img
                    alt="..."
                    src={skillsImage(skill)}
                    className="shadow-lg rounded-lg max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                                 <h3 className="text-xl font-bold text-center leading-relaxed m-4 text-gray-600">
                                    {skill}
                                </h3>
                  </div>
                </div>
  ))}
              </div>
       
    )
}

export default Skills
