//import Typed from 'react-typed';
import { ReactTyped } from "react-typed";
import Modal from "./form/Modal";

const Hero = () => {
  return (
    <div className='text-green-500'>
      <div className='max-w-[800px] mt-[-46px] w-full h-screen mx-auto text-center flex flex-col justify-center'>

        <h1 className='md:text-3xl sm:text-2xl text-3xl font-bold md:py-4' data-aos='fade-down' data-aos-duration='800'>
           Your story starts with us.</h1>
        <div className='flex justify-center items-center space-x-3'>
          <p className='md:text-3xl sm:text-2xl text-xl font-bold py-4'>
            We are a team with a wide variety of skills, who can take your
          </p>
          <ReactTyped
            className='md:text-3xl sm:text-2xl text-xl font-bold'
            strings={['Web application.', 'Web site.', 'SAAS.']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-green-400'>
          {`from design concept to realization, and through maintenance.`}
        </p>
  
			<Modal tip={"all"}/>
      </div>
			

    </div>
  );
};

export default Hero;
